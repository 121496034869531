import React, { useState, useEffect, lazy, Suspense } from "react";
import { SpinnerCircularFixed } from "../../assets/spinner/SpinnerCircularFixed";
import { Layout } from "../global/Layout";
import LayoutHOC from "./hocs/LayoutHOC";
import TabContainerHOC from "./hocs/TabContainerHOC";
import WelcomePage from "./WelcomePage";
import TopSliderPage from "./TopSliderPage";
import BannerFooterPage from "./BannerFooterPage";
import BannerLandscapePage from "./BannerLandscapePage";
import CategoriesPage from "./CategoriesPage";
import CentralCategoriesPage from "./CentralCategoriesPage";
import ValuesPage from "./ValuesPage";
import { useParams, useSearchParams } from "react-router-dom";
import TaglinePage from "./TaglinePage";

const LoadingComponent: React.FC = () => (
  <div className="spinner-div spinnerReport">
    <SpinnerCircularFixed className='' size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
  </div>
);

interface StoreCustomizationContainerProps {
  storeId?: string;
}

export default function StoreCustomizationContainer({ storeId }: StoreCustomizationContainerProps) {
  const [loading, setLoading] = useState(true);
  const { storeId: storeIdParams } = useParams<{ storeId: string }>();
  const [activeTab, setActiveTab] = useState("welcome");

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  const renderContent = () => {
    let ComponentToRender;

    switch (activeTab) {
      case "welcome":
        ComponentToRender = <WelcomePage loading={loading} onTabChange={handleTabChange} />;
        break;
      case "top-slider":
        ComponentToRender = <TopSliderPage model={{ storeId: storeId || storeIdParams || '' }} />;
        break;
      case "categories":
        ComponentToRender = <CategoriesPage />;
        break;
      case "banner-landscape":
        ComponentToRender = <BannerLandscapePage />;
        break;
      case "central-categories":
        ComponentToRender = <CentralCategoriesPage />;
        break;
      case "values":
        ComponentToRender = <ValuesPage />;
        break;
      case "banner-footer":
        ComponentToRender = <BannerFooterPage />;
        break;
        case "tagline":
          ComponentToRender = <TaglinePage />;
          break;
      default:
        ComponentToRender = <WelcomePage loading={loading} onTabChange={handleTabChange} />;
    }

    return (
      <div className="customization-tool-container">
        <LayoutHOC
          mainContent={
            <Suspense fallback={<LoadingComponent />}>
              {ComponentToRender}
            </Suspense>
          }
          onTabChange={handleTabChange}
          activeTab={activeTab}
        />
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingComponent />
      ) : (
        renderContent()
      )}

    </div>
  );
}