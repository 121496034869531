import React, { useState, useEffect } from 'react';
import TabContainerHOC from './hocs/TabContainerHOC';

export default function TaglinePage() {
    const [tagline, setTagline] = useState('');
    const [calling, setCalling] = useState(false);

    useEffect(() => {
        // Simulate API call to fetch tagline
        setCalling(true);
        setTimeout(() => {
            setTagline('Your default tagline here'); // Mock existing tagline
            setCalling(false);
        }, 1500);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTagline(e.target.value);
    };

    const saveTagline = () => {
        if (!tagline.trim()) {
            alert('Tagline cannot be empty!');
            return;
        }

        setCalling(true);
        // Simulate API call to save tagline
        setTimeout(() => {
            console.log('SEND TO API:', { tagline });
            setCalling(false);
            alert('SUCCESSFUL SAVE');
        }, 700);
    };

    return (
        <TabContainerHOC
            title="Tagline"
            description={<Description />}
            containerClass="tagline"
            loading={calling}
            commingSoon
        >
            <div className="row mt-5">
                <div className="col-sm col-left">
                    <h2>Edit the Site Tagline</h2>
                </div>
            </div>

            <div className="tagline-input-wrapper mt-4">
                <p><b>Tagline</b><span className="esl"> (Recommended 25 characters)</span></p>
                <input
                    type="text"
                    value={tagline}
                    onChange={handleChange}
                    className="input-round form-control mt-2"
                    placeholder="Type your tagline here."
                    disabled={calling}
                />
            </div>

            <div className="navigationFooter mt-5">
                <a className="backBtn">BACK</a>
                <span className={`saveBtn ${calling ? 'disabled' : ''}`} onClick={saveTagline}>
                    SAVE
                </span>
            </div>
        </TabContainerHOC>
    );
}

const Description = () => (
    <ul>
        <li>Enter a tagline with a <b>maximum of 50 characters.</b></li>
    </ul>
);